import { RouterView } from "vue-router";

const CarteirasList = () => import('@/pages/carteiras/Index.vue')
const CarteirasForm = () => import('@/pages/carteiras/Form.vue')

export default {
  path: '/carteiras',
  name: 'carteiras',
  component: RouterView,
  children: [
    {
      path: '',
      name: 'carteiras-list',
      component: CarteirasList,
      meta: {
        title: 'Listagem de carteiras'
      }
    },
    {
      path: 'new',
      name: 'carteiras-new',
      component: CarteirasForm,
      meta: {
        title: 'Nova carteira'
      }
    },
    {
      path: ':id',
      name: 'carteiras-edit',
      component: CarteirasForm,
      meta: {
        title: 'Editar carteira'
      }
    },
  ]
}