import { createRouter, createWebHashHistory } from 'vue-router';

import CarteirasModule from './pages/carteiras/route'
import ClientesModule from './pages/clientes/route'
import DevedoresModule from './pages/devedores/route'
import DividasModule from './pages/dividas/route'
import ExportacoesModule from './pages/exportacoes/route'
import PainelModule from './pages/painel/route'
import PoliticaDeNegociacaoModule from './pages/politica-de-negociacao/route'
import ProfileModule from './pages/profile/route'
import RelatoriosModule from './pages/relatorios/route'
import UsuariosModule from './pages/usuarios/route'

const routes = [
  {
    path: '/',
    redirect: '/painel',
    name: 'home',
    component: () => import('@/App.vue'),
    children: [
      CarteirasModule,
      ClientesModule,
      DevedoresModule,
      DividasModule,
      ExportacoesModule,
      PainelModule,
      PoliticaDeNegociacaoModule,
      ProfileModule,
      RelatoriosModule,
      UsuariosModule
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
    meta: {
      disableAuth: true
    }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/painel",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
