<template>
  <ProgressSpinner class="medium-loading"/>
</template>

<style scoped>
.medium-loading {
  width:50px;
  height:50px
}
</style>
