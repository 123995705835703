import { RouterView } from 'vue-router'

const UsuariosList = () => import('@/pages/usuarios/Index.vue')
const UsuariosForm = () => import('@/pages/usuarios/Form.vue')

export default {
  path: 'usuarios',
  name: 'Usuários',
  redirect: '/usuarios/list',
  component: RouterView,
  children: [
    {
      path: 'list',
      name: 'Listagem de Usuários',
      component: UsuariosList
    },
    {
      path: 'new',
      name: 'Novo Usuário',
      component: UsuariosForm
    },
    {
      path: ':id',
      name: 'Editar Usuário',
      component: UsuariosForm
    },
  ]
}