import { RouterView } from "vue-router";

const DividasList = () => import('@/pages/dividas/Index.vue')
const DividasForm = () => import('@/pages/dividas/Form.vue')

export default {
  path: '/dividas',
  name: 'dividas',
  component: RouterView,
  children: [
    {
      path: '',
      name: 'dividas-list',
      component: DividasList
    },
    {
      path: 'new',
      name: 'dividas-new',
      component: DividasForm,
      meta: {
        title: 'Adicionar dívida'
      }
    },
    {
      path: ':id',
      name: 'dividas-edit',
      component: DividasForm,
      meta: {
        title: 'Adicionar dívida'
      }
    }
  ]
}