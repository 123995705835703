import { getClient } from './http'

// TODO: rever login.. actions no back etc se vai ser generico e divisao por role ou se vai ter areas separadas
export async function login(email, password) {
  const client = getClient()
  const path = '/admin/signin';
  const { data } = await client.post(path, {
    email,
    password
  })
  if (data.token) {
    localStorage.setItem('token', data.token)
  }
}

export function getToken() {
  return localStorage.getItem('token')
}

export async function saveProfile(profile) {
  const { data } = await getClient().put('/admin/@me', profile)
  return data
}

export async function updatePassword(passwords) {
  const { data } = await getClient().put('/admin/@me/password', passwords)
  return data
}

export async function getProfile() {
  const path = '/admin/@me'; // /auth/@me
  const { data } = await getClient().get(path)
  return data
}

export function logout(callback) {
  localStorage.removeItem('token')
  if (callback) {
    callback()
  }
}

export function isLogged() {
  return !!localStorage.getItem('token')
}
