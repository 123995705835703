export default {
  cpf: function (value) {
    if (!value) return '';
    value = value.toString().padStart(11, '0');
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  },
  cnpj: function (value) {
    if (!value) return '';
    value = value.toString().padStart(14, '0');
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  },
  cpfCnpj: function (value) {
    if (!value) return '';
    if (value.length <= 11) return this.cpf(value);
    if (value.length > 11) return this.cnpj(value);
  },
  date: function (value) {
    if (!value) return '';
    const date = value.split('T')[0];
    return date.replace(/^(\d{4})-(\d{1,2})-(\d{1,2})/, '$3/$2/$1');
  },
  dateTime: function (value) {
    if (!value) return '';
    const date = new Date(value);
    return `${date.toLocaleDateString()} às ${date.toLocaleTimeString()}`;
  },
  telefone: function (value) {
    if (!value) return '';
    value = value.toString().padStart(11, '0');
    return value.replace(/^(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
  }
}