import { RouterView } from "vue-router";

const ClientesList = () => import('@/pages/clientes/Index.vue')
const ClientesForm = () => import('@/pages/clientes/Form.vue')
const ClientesRegrasList = () => import('@/pages/clientes/regras/Index.vue')
const ClientesRegrasForm = () => import('@/pages/clientes/regras/Form.vue')

export default {
  path: 'clientes',
  name: 'Clientes',
  redirect: '/clientes/list',
  component: RouterView,
  children: [
    {
      path: 'list',
      name: 'Listagem de Clientes',
      component: ClientesList
    },
    {
      path: 'new',
      name: 'Novo Cliente',
      component: ClientesForm
    },
    {
      path: ':id',
      name: 'Editar Cliente',
      component: ClientesForm
    },
    {
      path: ':client/regras',
      name: 'Regras',
      component: ClientesRegrasList,
    },
    {
      path: ':client/regras/new',
      name: 'Nova Regra',
      component: ClientesRegrasForm,
    },
    {
      path: ':client/regras/:id',
      name: 'Editar Regra',
      component: ClientesRegrasForm,
    },
  ]
}