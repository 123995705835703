const PoliticaDeNegociacao = () => import("./politica-de-negociacao.vue");
const PoliticaDeNegociacaoLista = () =>
  import("./components/politica-de-negociacao-lista.component.vue");
const PoliticaDeNegociacaoForm = () =>
  import("./components/politica-de-negociacao-form.component.vue");

export default {
  path: "/politica-de-negociacao",
  component: PoliticaDeNegociacao,
  children: [
    {
      path: "",
      name: "politica-de-negociacao",
      component: PoliticaDeNegociacaoLista,
    },
    {
      path: "adicionar",
      name: "politica-de-negociacao-form",
      component: PoliticaDeNegociacaoForm,
    },
    {
      path: "editar/:id",
      name: "politica-de-negociacao-editar",
      component: PoliticaDeNegociacaoForm,
    },
  ],
};
