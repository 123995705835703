import { RouterView } from "vue-router";

const DevedoresList = () => import('@/pages/devedores/Index.vue')
const DevedoresForm = () => import('@/pages/devedores/Form.vue')
const DevedoresHistory = () => import('@/pages/devedores/History.vue')

export default {
  path: '/devedores',
  name: 'devedores',
  component: RouterView,
  children: [
    {
      path: '',
      name: 'devedores-list',
      component: DevedoresList
    },
    {
      path: ':documento/history',
      name: 'devedores-history',
      component: DevedoresHistory,
      meta: {
        title: 'Histórico de alterações'
      }
    },
    {
      path: ':id/record',
      name: 'devedores-record',
      component: DevedoresForm,
      meta: {
        title: 'Ficha do devedor',
        readOnly: true
      }
    }
  ]
}