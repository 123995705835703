import axios from 'axios'
import Router from '../router';
import { getToken, logout } from './auth'

export function getClient(auxHeaders = {}) {
  let headers = getToken() ? { 'Authorization': `Bearer ${getToken()}` } : {}
  headers = { ...headers, ...auxHeaders }

  const client = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers
  })

  client.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error?.response?.status === 401) {
      logout();
      Router.push({ name: 'login' });
    }
    return Promise.reject(error)
  })

  return client
}
